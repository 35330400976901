.tile__intro {
    width: 100%;
    background-color: var(--clr-tile);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2em 1em;
}

.self_image {
    max-width: 28em;
}

.intro {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
}

.intro__section {
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
}

.intro__subtitle {
    /*background-color: var(--clr-main);*/
    background-color:black;
    /*color: var(--clr-light);*/
    color: white;
    text-align: center; 
    padding-right: 20px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

@media (min-width: 800px) {
    .intro {
        flex-direction: row;
    }

    .intro__section {
        padding-left: 1em;
        padding-top: 2em;
    }
}
