*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Custom properties */
html {
  scroll-behavior: smooth;
}

:root {
  --clr-light: rgb(252, 252, 252);
  --clr-dark: hsl(0, 0%, 11%);
  --clr-accent: #ce4646;
  --clr-main: #9d1f0eb4;
  --clr-accent: #42c25c9c;
  --clr-tile: #f5f5f5;

  --fs-h1: 3rem;
  --fs-h2: 2,25rem;
  --fs-h3: 1.25rem;
  --fs-body: 1rem;
}

@media (min-width: 800px) {
  :root {
    --fs-h1: 4.5rem;
    --fs-h2: 3.75rem;
    --fs-h3: 1.5rem;
    --fs-body: 1.125rem;
  }
}

/* General styles */

body {
  color: var(--clr-dark);
  margin: 0;
  font-family: var(--ff-primary);
  font-size: var(--fs-body);
  line-height: 1.3;
}

img {
  display: block;
  max-width: 100%;
}

:focus {
  outline: 3px solid var(--clr-accent);
  outline-offset: 3px;
}

.App {
  font-family: 'Montserrat', sans-serif;
}