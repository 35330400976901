.cont__tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2em;
}

.contacts body {
    color: orange;
}
