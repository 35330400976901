.logo {
    height: 3em;
}
.nav__item {
    margin: 0 6px;
}
.nav__list {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%
}

@media (max-width: 800px) {
    .nav__list {
        width: 270px;
    }
}


.nav__link {
    text-decoration: none;
    color: inherit;
}

header {
    display: flex;
    justify-content: space-between;
    padding: 1em;
}